import { FC } from 'react'

import { AppPage, CardedContent, Button, TabSections, DefaultErrorPage } from '@web-panel/shared'
import { useIntl, FormattedMessage } from 'react-intl'
import { useParams, useNavigate } from 'react-router-dom'
import modelIcons from '@local/model-icons'
import { pageWithRoleAuthorization, useRoleAuthorization } from '@local/components/RoleAuthorized'
import { useOutlet } from '@web-panel/oc-api'
import { GeneralTab } from './Tabs/General'
import EmployeesTab from './Tabs/Employees'
import OrdersTab from './Tabs/Orders'
import OutletStatusChangeButton from './Components/OutletStatusChangeButton'
import { OutletRefundablesTab } from './Tabs/RefundablesTab'
import { ContractTab } from './Tabs/Contract'
import { OneTimeContractTab } from './Tabs/OneTimeContract'

const PreviewPage: FC = () => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const params = useParams()
  const { data: outlet, loading } = useOutlet({ id: params.id ?? '' })
  const { can } = useRoleAuthorization()

  if (loading) return null
  if (!outlet)
    return (
      <DefaultErrorPage
        title={formatMessage({ id: 'errors.404.title' })}
        message={formatMessage({ id: 'errors.404.message' })}
      />
    )

  const headerActionButtons = can('outlet', 'update')
    ? [
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          onClick={() => navigate(`/boxes/outlet/${params.id}`)}
        >
          <FormattedMessage
            id="common.actions.view-with-name"
            values={{ name: formatMessage({ id: 'models.box.plural' }) }}
          />
        </Button>,
        <OutletStatusChangeButton outlet={outlet} />,
        <Button fullWidth variant="contained" color="secondary" onClick={() => navigate('edit')}>
          <FormattedMessage
            id="common.actions.edit-with-name"
            values={{ name: formatMessage({ id: 'models.outlet' }) }}
          />
        </Button>,
      ]
    : undefined

  return (
    <AppPage
      backButton
      icon={modelIcons.outlet}
      title={outlet.location.name}
      actionButtons={headerActionButtons}
    >
      <CardedContent>
        <TabSections
          sections={[
            {
              key: 'general',
              title: formatMessage({ id: 'pages.outlet-preview.tabs.general' }),
              Content: () => <GeneralTab outlet={outlet} />,
            },
            {
              key: 'employees',
              title: formatMessage({ id: 'pages.outlet-preview.tabs.employees' }),
              Content: () => <EmployeesTab outlet={outlet} />,
            },
            {
              key: 'orders',
              title: formatMessage({ id: 'models.outlet-preview.tabs.orders' }),
              Content: () => <OrdersTab outlet={outlet} />,
            },
            {
              key: 'refundables',
              enabled: can('outlet', 'listRefundables'),
              title: formatMessage({ id: 'models.outlet-preview.tabs.refundables' }),
              Content: () => <OutletRefundablesTab outletId={outlet.id} />,
            },
            {
              key: 'one-time-contract',
              title: formatMessage({ id: 'pages.outlet-preview.tabs.one-time-settings' }),
              Content: () => <OneTimeContractTab outlet={outlet} />,
              enabled: can('oneTimeContractSetting', 'list'),
            },
            {
              key: 'contract',
              title: formatMessage({ id: 'models.outlet-preview.tabs.contract' }),
              Content: () => <ContractTab outlet={outlet} />,
            },
          ]}
        />
      </CardedContent>
    </AppPage>
  )
}

export default pageWithRoleAuthorization(PreviewPage, ({ can }) => can('outlet', 'preview'))
