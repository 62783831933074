import React from 'react'

// Inline styles as objects
const containerStyle: React.CSSProperties = {
  display: 'flex',
  width: '100%',
  fontFamily: 'Inter, sans-serif',
  backgroundColor: '#fff',
}

const sidebarStyle: React.CSSProperties = {
  width: '17%',
  backgroundColor: '#CFF1FC',
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}

const logoStyle: React.CSSProperties = {
  width: '70%',
  marginBottom: '20px',
}

const stepStyle: React.CSSProperties = {
  textAlign: 'center',
  marginBottom: '20px',
}

const stepImgStyle: React.CSSProperties = {
  marginTop: '10px',
}

const mainContentStyle: React.CSSProperties = {
  width: '80%',
  padding: '20px',
}

const dashboardTitleStyle: React.CSSProperties = {
  fontSize: '40px',
  fontWeight: 'bold',
}

const kpiCardsStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  margin: '50px',
  marginBottom: '70px',
}

const kpiCardStyle: React.CSSProperties = {
  backgroundColor: '#CFF1FC',
  width: '25%',
  padding: '20px',
  borderRadius: '20px',
  textAlign: 'center',
  alignContent: 'center',
  height: '190px',
}

const kpiCardTitleStyle: React.CSSProperties = {
  margin: '0',
  fontSize: '20px',
  fontWeight: 'bold',
}

const kpiCardValueStyle: React.CSSProperties = {
  fontSize: '44px',
  fontWeight: 'bold',
  margin: '0',
}

const boxFillLeaderboardSectionStyle: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '20px',
}

const boxStyle: React.CSSProperties = {
  width: '47.5%',
  padding: '20px',
  borderRadius: '20px',
  borderColor: '#D9D9D9',
  borderWidth: '3px',
}

const leaderboardStyle: React.CSSProperties = {
  width: '47.5%',
  borderColor: '#CFF1FC',
  borderRadius: '20px',
  padding: '20px',
  borderWidth: '3px',
}

const tableStyle: React.CSSProperties = {
  width: '100%',
  borderCollapse: 'collapse',
}

const thTdStyle: React.CSSProperties = {
  textAlign: 'center',
  padding: '8px',
  fontSize: '16px',
}

const thStyle: React.CSSProperties = {
  textAlign: 'center',
  padding: '8px',
  borderBottom: '2px',
  borderStyle: 'solid',
  borderColor: '#CFF1FC',
  fontSize: '18px',
}

const evenRowStyle: React.CSSProperties = {
  backgroundColor: '#f2f2f2',
}

export const PageStyles = {
  evenRowStyle,
  tableStyle,
  thTdStyle,
  thStyle,
  leaderboardStyle,
  logoStyle,
  boxFillLeaderboardSectionStyle,
  boxStyle,
  sidebarStyle,
  stepStyle,
  kpiCardStyle,
  kpiCardTitleStyle,
  kpiCardValueStyle,
  kpiCardsStyle,
  containerStyle,
  dashboardTitleStyle,
  mainContentStyle,
  stepImgStyle,
}
