import { FC } from 'react'
import { InfinitScroller } from '@web-panel/shared'
import { useLabels } from '@web-panel/oc-api'
import { Address } from '@web-panel/api'
import IndexTable from './Tables/IndexTable'
import { useSorting } from './sorting'

type LabelTabProps = {
  address: Address
  status?: string
  search?: string
}

export const LabelTab: FC<LabelTabProps> = ({ address, status, search }) => {
  const { field: sortBy, direction: sortOrder } = useSorting()

  const {
    data: labels = [],
    loading,
    hasMore,
    loadMore,
  } = useLabels(
    { partnerAddressId: address.id, status, search, sortBy, sortOrder },
    { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' }
  )

  const onLoadMore = () => {
    if (!hasMore) return
    loadMore()
  }

  return (
    <InfinitScroller onLoadMore={onLoadMore}>
      <IndexTable data={labels} loading={loading} />
    </InfinitScroller>
  )
}
