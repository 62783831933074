import { RouteConfig, AuthLayout, InfoLayout, BlankLayout } from '@web-panel/shared'
import _compact from 'lodash/compact'
import { PublicLayout } from './layouts/PublicLayout'
import { Error404 } from './pages/404'
import { ComingSoonPage } from './pages/ComingSoon'
import SignInPage from './pages/SignIn'
import ConfirmEmailPage from './pages/ConfirmEmail'
import AuthEmailPage from './pages/AuthEmail'
import ProductItemsIndexPage from './pages/ProductItems/IndexPage'
import ProductItemsPreviewPage from './pages/ProductItems/PreviewPage'
import ProductItemsEditPage from './pages/ProductItems/EditPage'
import OutletsIndexPage from './pages/Outlets/IndexPage'
import OutletsPreviewPage from './pages/Outlets/PreviewPage'
import OutletsEditPage from './pages/Outlets/EditPage'
import OutletsCreatePage from './pages/Outlets/CreatePage'
import OutletsCreateContractPackagePage from './pages/Outlets/CreateContractPackagePage'
import OutletsRebuildContractPackagePage from './pages/Outlets/RebuildContractPackagePage'
import OutletsCreateOneTimeContractSettingPage from './pages/Outlets/CreateOneTimeContractSettingPage'
import PreviewOneTimeContractSettingPage from './pages/Outlets/PreviewOneTimeContractSettingPage'
import UpdateOneTimeContractSettingPage from './pages/Outlets/UpdateOneTimeContractSettingPage'
import { OutletRefundablePreviewPage } from './pages/Outlets/RefundablesPreviewPage'
import { OutletRefundableEditPage } from './pages/Outlets/RefundablesEditPage'
import PartnersIndexPage from './pages/Partners/IndexPage'
import { PartnersPreviewPage } from './pages/Partners/PreviewPage'
import { PartnersEditPage } from './pages/Partners/EditPage'
import { PartnersConsumablesPreviewPage } from './pages/Partners/ConsumablesPreviewPage'
import { PartnersConsumablesEditPage } from './pages/Partners/ConsumablesEditPage'
import { PartnerRefundablePreviewPage } from './pages/Partners/RefundablesPreviewPage'
import { PartnerRefundableEditPage } from './pages/Partners/RefundablesEditPage'
import PartnerCampaignPreviewPage from './pages/Partners/Campaigns/PreviewPage'
import OrdersIndexPage from './pages/Orders/IndexPage'
import OrdersPreviewPage from './pages/Orders/PreviewPage'
import OrdersCreatePage from './pages/Orders/CreatePage'
import BatchesIndexPage from './pages/ProductItemBatches/IndexPage'
import BatchesCreatePage from './pages/ProductItemBatches/CreatePage'
import BatchesPreviewPage from './pages/ProductItemBatches/PreviewPage'
import BoxesIndexPage from './pages/Boxes/IndexPage'
import BoxesPreviewPage from './pages/Boxes/PreviewPage'
import BoxesEditPage from './pages/Boxes/EditPage'
import AdminUsersIndexPage from './pages/AdminUsers/IndexPage'
import AdminUsersCreatePage from './pages/AdminUsers/CreatePage'
import AdminUsersPreviewPage from './pages/AdminUsers/PreviewPage'
import { AdminUsersEditPage } from './pages/AdminUsers/EditPage'
import AppUsersIndexPage from './pages/AppUsers/IndexPage'
import AppUsersPreviewPage from './pages/AppUsers/PreviewPage'
import AppUserUpdateWalletPage from './pages/AppUsers/UpdateWalletPage'
import AppUserTopUpWalletPage from './pages/AppUsers/TopUpWalletPage'
import PartnerEmployeesPreviewPage from './pages/PartnerEmployees/PreviewPage'
import PartnerEmployeesEditPage from './pages/PartnerEmployees/EditPage'
import PartnerEmployeeCreatePage from './pages/PartnerEmployees/CreatePage'
import PaymentOutsIndexPage from './pages/PaymentOuts/IndexPage'
import PaymentOutsPreviewPage from './pages/PaymentOuts/PreviewPage'
import ExternalRouter from './pages/ExternalRouter'
import DonationsIndexPage from './pages/Donations/IndexPage'
import DonationPreviewPage from './pages/Donations/PreviewPage'
import { SettingsIndexPage } from './pages/Settings/IndexPage'
import { SettingsConsumablesPreviewPage } from './pages/Settings/Consumables/ConsumablesPreviewPage'
import { SettingsCreateConsumablePage } from './pages/Settings/Consumables/CreateConsumablePage'
import { SettingsEditConsumablePage } from './pages/Settings/Consumables/EditConsumablePage'
import { SettingsCityPreviewPage } from './pages/Settings/City/CityPreviewPage'
import { SettingsCreateCityPage } from './pages/Settings/City/CreateCityPage'
import { SettingsEditCityPage } from './pages/Settings/City/EditCityPage'
import { SettingsCountryPreviewPage } from './pages/Settings/Country/CountryPreviewPage'
import { SettingsCountryEditPage } from './pages/Settings/Country/CountryEditPage'
import { SettingsCountryRefundablePreviewPage } from './pages/Settings/Country/CountryRefundablePreviewPage'
import { SettingsCountryRefundableEditPage } from './pages/Settings/Country/CountryRefundableEditPage'
import { SettingsCityRefundablePreviewPage } from './pages/Settings/City/CityRefundablePreviewPage'
import { SettingsCityRefundableEditPage } from './pages/Settings/City/CityRefundableEditPage'
import SettingsBoxRentalConfigurationCreatePage from './pages/Settings/BoxRentalConfigurations/CreatePage'
import SettingsBoxRentalConfigurationUpdatePage from './pages/Settings/BoxRentalConfigurations/UpdatePage'
import DispensersIndexPage from './pages/Dispensers/IndexPage'
import DispenserPreviewPage from './pages/Dispensers/PreviewPage'
import DispenserEditPage from './pages/Dispensers/EditPage'
import DispenserCreateCustomLocationPage from './pages/Dispensers/CreateCustomLocationPage'
import DispenserUpdateCustomLocationPage from './pages/Dispensers/UpdateCustomLocationPage'
import RentalsIndexPage from './pages/Rentals/IndexPage'
import RentalsPreviewPage from './pages/Rentals/PreviewPage'
import RentalsUpdatePage from './pages/Rentals/UpdatePage'
import RentalsCreatePage from './pages/Rentals/CreatePage'
import PublicBoxReport from './pages/PublicBoxReport/IndexPage'
import ContainersIndexPage from './pages/Containers/IndexPage'
import ContainersPreviewPage from './pages/Containers/PreviewPage'
import AddressesIndexPage from './pages/Addresses/IndexPage'
import { AddressesPreviewPage } from './pages/Addresses/PreviewPage'
import TrackingPreviewPage from './pages/Tracking/IndexPage'
import GroupLabelsIndexPage from './pages/GroupLabels/IndexPage'
import GroupLabelsPreviewPage from './pages/GroupLabels/PreviewPage'
import SequencesIndexPage from './pages/Sequences/IndexPage'
import SequenceNodesIndexPage from './pages/SequenceNodes/IndexPage'
import GreinerDashboardIndexPage from './pages/GreinerDashboard/IndexPage'

const routes: RouteConfig = _compact([
  {
    key: 'signin',
    path: '/sign-in',
    Component: () => <SignInPage />,
    Layout: AuthLayout,
    accessStrategy: 'public-only',
  },
  {
    key: 'authenticate',
    path: '/authenticate',
    Component: ConfirmEmailPage,
    Layout: InfoLayout,
    accessStrategy: 'public-only',
  },
  {
    key: 'email-confirmation',
    path: '/email-confirmation',
    Component: ConfirmEmailPage,
    Layout: InfoLayout,
    accessStrategy: 'public-only',
  },
  {
    key: 'post-auth-email',
    path: '/auth-email',
    Component: AuthEmailPage,
    Layout: InfoLayout,
    accessStrategy: 'public-only',
  },
  {
    key: 'cups-index',
    path: '/product-items',
    Component: ProductItemsIndexPage,
    accessStrategy: 'authenticated',
    routes: [
      {
        key: 'cups-preview',
        path: '/:id',
        Component: ProductItemsPreviewPage,
      },
      {
        key: 'cups-edit',
        path: '/:id/edit',
        Component: ProductItemsEditPage,
      },
    ],
  },
  {
    key: 'product-item-batches-index',
    path: '/product-item-batches',
    Component: BatchesIndexPage,
    accessStrategy: 'authenticated',
    routes: [
      {
        key: 'product-item-batches-create',
        path: '/create',
        Component: BatchesCreatePage,
      },
      {
        key: 'product-item-batches-preview',
        path: '/:id',
        Component: BatchesPreviewPage,
      },
    ],
  },
  {
    key: 'boxes',
    path: '/boxes',
    Component: BoxesIndexPage,
    accessStrategy: 'authenticated',
    routes: [
      {
        key: 'boxes-outlet',
        path: 'outlet/:outletId',
        Component: BoxesIndexPage,
        accessStrategy: 'authenticated',
      },
      {
        key: 'boxes-outlet-preview',
        path: 'outlet/:outletId/:id',
        Component: BoxesPreviewPage,
        accessStrategy: 'authenticated',
      },
      {
        key: 'boxes-preview',
        path: '/:id',
        Component: BoxesPreviewPage,
      },
      {
        key: 'boxes-edit',
        path: '/:id/edit',
        Component: BoxesEditPage,
      },
    ],
  },
  {
    key: 'outlets-index',
    path: '/outlets',
    Component: OutletsIndexPage,
    accessStrategy: 'authenticated',
    routes: [
      {
        key: 'outlets-preview',
        path: '/:id',
        Component: OutletsPreviewPage,
        routes: [
          {
            key: 'outlets-refundables-preview',
            path: '/refundables/:refundableId',
            Component: OutletRefundablePreviewPage,
          },
          {
            key: 'outlets-refundables-edit',
            path: '/refundables/:refundableId/edit',
            Component: OutletRefundableEditPage,
          },
          {
            key: 'create-contact-settings',
            path: '/create-contract-package',
            Component: OutletsCreateContractPackagePage,
          },
          {
            key: 'rebuild-contact-settings',
            path: '/rebuild-contract-package',
            Component: OutletsRebuildContractPackagePage,
          },
          {
            key: 'create-one-time-contract',
            path: '/create-one-time-contract',
            Component: OutletsCreateOneTimeContractSettingPage,
          },
          {
            key: 'preview-one-time-contract',
            path: '/one-time-contract/:contractSettingId',
            Component: PreviewOneTimeContractSettingPage,
          },
          {
            key: 'update-one-time-contract',
            path: '/one-time-contract/:contractSettingId/update',
            Component: UpdateOneTimeContractSettingPage,
          },
        ],
      },
      {
        key: 'outlets-edit',
        path: '/:id/edit',
        Component: OutletsEditPage,
      },
      {
        key: 'outlets-create',
        path: '/create',
        Component: OutletsCreatePage,
      },
    ],
  },
  {
    key: 'addresses-index',
    path: '/addresses',
    Component: AddressesIndexPage,
    accessStrategy: 'authenticated',
    routes: [
      {
        key: 'addresses-preview',
        path: '/:id',
        Component: AddressesPreviewPage,
      },
    ],
  },
  {
    key: 'tracking-index',
    path: '/tracking/:id/:trackingNumber',
    Component: TrackingPreviewPage,
    accessStrategy: 'authenticated',
    routes: [],
  },
  {
    key: 'sequences-index',
    path: '/sequences',
    Component: SequencesIndexPage,
    accessStrategy: 'authenticated',
    routes: [],
  },
  {
    key: 'sequence-nodes-index',
    path: '/sequence-nodes/:id/:name/:type',
    Component: SequenceNodesIndexPage,
    accessStrategy: 'authenticated',
    routes: [],
  },
  {
    key: 'group-labels-index',
    path: '/group-labels',
    Component: GroupLabelsIndexPage,
    accessStrategy: 'authenticated',
    routes: [
      {
        key: 'group-labels-preview',
        path: '/:id',
        Component: GroupLabelsPreviewPage,
      },
    ],
  },
  {
    key: 'partners-index',
    path: '/partners',
    Component: PartnersIndexPage,
    accessStrategy: 'authenticated',
    routes: [
      {
        key: 'partners-preview',
        path: '/:id',
        Component: PartnersPreviewPage,
        routes: [
          {
            key: 'employee-preview',
            path: '/employee/:employeeId',
            Component: PartnerEmployeesPreviewPage,
          },
          {
            key: 'employee-edit',
            path: '/employee/:employeeId/edit',
            Component: PartnerEmployeesEditPage,
          },
          {
            key: 'eployee-create',
            path: '/employee/create',
            Component: PartnerEmployeeCreatePage,
          },
          {
            key: 'partners-consumable-preview',
            path: '/consumables/:consumableId',
            Component: PartnersConsumablesPreviewPage,
          },
          {
            key: 'partners-consumable-edit',
            path: '/consumables/:consumableId/edit',
            Component: PartnersConsumablesEditPage,
          },
          {
            key: 'partners-refundable-preview',
            path: '/refundables/:refundableId',
            Component: PartnerRefundablePreviewPage,
          },
          {
            key: 'partners-refundable-edit',
            path: '/refundables/:refundableId/edit',
            Component: PartnerRefundableEditPage,
          },
          {
            key: 'campaign-preview',
            path: '/campaigns/:campaignId',
            Component: PartnerCampaignPreviewPage,
          },
        ],
      },
      {
        key: 'partners-edit',
        path: '/:id/edit',
        Component: PartnersEditPage,
      },
    ],
  },
  {
    key: 'orders-index',
    path: '/orders',
    Component: OrdersIndexPage,
    accessStrategy: 'authenticated',
    routes: [
      {
        key: 'orders-preview',
        path: '/:id',
        Component: OrdersPreviewPage,
      },
      {
        key: 'orders-create',
        path: '/create',
        Component: OrdersCreatePage,
      },
    ],
  },
  {
    key: 'dashboard',
    path: '/',
    Component: ComingSoonPage,
    accessStrategy: 'authenticated',
  },
  {
    key: 'app-users',
    path: '/app-users',
    Component: AppUsersIndexPage,
    accessStrategy: 'authenticated',
    routes: [
      {
        key: 'app-users-preview',
        path: '/:id',
        Component: AppUsersPreviewPage,
        routes: [
          {
            key: 'update-wallet',
            path: 'update-wallet',
            Component: AppUserUpdateWalletPage,
          },
          {
            key: 'top-up-wallet',
            path: 'top-up',
            Component: AppUserTopUpWalletPage,
          },
        ],
      },
    ],
  },
  {
    key: 'payout-requests',
    path: '/payout-requests',
    Component: PaymentOutsIndexPage,
    accessStrategy: 'authenticated',
    routes: [
      {
        key: 'payout-requests-preview',
        path: '/:id',
        Component: PaymentOutsPreviewPage,
      },
    ],
  },
  {
    key: 'containers',
    path: '/containers',
    Component: ContainersIndexPage,
    accessStrategy: 'authenticated',
    routes: [
      {
        key: 'containers-preview',
        path: '/:id',
        Component: ContainersPreviewPage,
      },
    ],
  },
  {
    key: 'checkout-plates',
    path: '/checkout-plates',
    Component: ComingSoonPage,
    accessStrategy: 'authenticated',
  },
  {
    key: 'admin-users',
    path: '/admin-users',
    Component: AdminUsersIndexPage,
    accessStrategy: 'authenticated',
    routes: [
      {
        key: 'admin-users-preview',
        path: '/:id',
        Component: AdminUsersPreviewPage,
      },
      {
        key: 'admin-users-create',
        path: '/create',
        Component: AdminUsersCreatePage,
      },
      {
        key: 'admin-users-edit',
        path: '/:id/edit',
        Component: AdminUsersEditPage,
      },
    ],
  },
  {
    key: 'donations',
    path: '/donations',
    Component: DonationsIndexPage,
    accessStrategy: 'authenticated',
    routes: [
      {
        key: 'donations-preview',
        path: '/:id',
        Component: DonationPreviewPage,
      },
    ],
  },
  {
    key: 'external-router',
    path: '/external',
    Component: ExternalRouter,
    accessStrategy: 'authenticated-and-public',
  },
  {
    key: 'public-box-report',
    path: '/public/box/:boxId/report',
    Component: PublicBoxReport,
    accessStrategy: 'public-only',
    Layout: PublicLayout,
  },
  {
    key: 'settings',
    path: '/settings',
    Component: SettingsIndexPage,
    accessStrategy: 'authenticated',
    routes: [
      {
        key: 'consumable-preview',
        path: '/consumables/:id',
        Component: SettingsConsumablesPreviewPage,
      },
      {
        key: 'consumable-edit',
        path: '/consumables/:id/edit',
        Component: SettingsEditConsumablePage,
      },
      {
        key: 'consumable-create',
        path: '/consumables/create',
        Component: SettingsCreateConsumablePage,
      },
      {
        key: 'city-preview',
        path: '/cities/:code',
        Component: SettingsCityPreviewPage,
      },
      {
        key: 'city-edit',
        path: '/cities/:code/edit',
        Component: SettingsEditCityPage,
      },
      {
        key: 'city-refundable-preview',
        path: '/cities/:code/refundables/:id',
        Component: SettingsCityRefundablePreviewPage,
      },
      {
        key: 'city-refundable-edit',
        path: '/cities/:code/refundables/:id/edit',
        Component: SettingsCityRefundableEditPage,
      },
      {
        key: 'city-create',
        path: '/cities/create',
        Component: SettingsCreateCityPage,
      },
      {
        key: 'country-preview',
        path: '/countries/:code',
        Component: SettingsCountryPreviewPage,
      },
      {
        key: 'country-edit',
        path: 'countries/:code/edit',
        Component: SettingsCountryEditPage,
      },
      {
        key: 'country-refundable-preview',
        path: '/countries/:code/refundables/:id',
        Component: SettingsCountryRefundablePreviewPage,
      },
      {
        key: 'country-refundable-edit',
        path: '/countries/:code/refundables/:id/edit',
        Component: SettingsCountryRefundableEditPage,
      },
      {
        key: 'update-box-rental-configuration',
        path: '/box-rental-configurations/:id/edit',
        Component: SettingsBoxRentalConfigurationUpdatePage,
      },
      {
        key: 'create-box-rental-configuration',
        path: '/box-rental-configurations/new',
        Component: SettingsBoxRentalConfigurationCreatePage,
      },
    ],
  },
  {
    key: 'rentals',
    path: '/rentals',
    Component: RentalsIndexPage,
    accessStrategy: 'authenticated',
    routes: [
      {
        key: 'rentas-preview',
        path: '/:id',
        Component: RentalsPreviewPage,
      },
      {
        key: 'rentas-update',
        path: '/:id/edit',
        Component: RentalsUpdatePage,
      },
      {
        key: 'rentas-create',
        path: '/new',
        Component: RentalsCreatePage,
      },
    ],
  },
  {
    key: 'dispensers',
    path: '/dispensers',
    Component: DispensersIndexPage,
    accessStrategy: 'authenticated',
    routes: [
      {
        key: 'dispensers-preview',
        path: '/:id',
        Component: DispenserPreviewPage,
      },
      {
        key: 'dispeners-edit',
        path: '/:id/edit',
        Component: DispenserEditPage,
      },
      {
        key: 'dispensers-create-custom-location',
        path: '/:id/custom-location/create',
        Component: DispenserCreateCustomLocationPage,
      },
      {
        key: 'dispensers-update-custom-location',
        path: '/:id/custom-location/update',
        Component: DispenserUpdateCustomLocationPage,
      },
    ],
  },
  {
    key: 'greiner-dashboard',
    path: '/greiner/dashboard/:outletId',
    Layout: BlankLayout,
    Component: GreinerDashboardIndexPage,
  },
  {
    key: '404',
    path: '*',
    Component: Error404,
    accessStrategy: 'authenticated',
  },
])

export default routes
