import { FC } from 'react'
import { Label } from '@web-panel/api'
import { Paper, TableGrid, useFormatDate } from '@web-panel/shared'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useRoleAuthorization } from '@local/components/RoleAuthorized'
import LabelStatusChip from '@local/components/LabelStatusChip'
import GroupLabelAddressLink from '@local/components/AddressLink'

type PreviewTableProps = {
  data: Label[]
  loading: boolean
}

const PreviewTable: FC<PreviewTableProps> = ({ data, loading }) => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const { cannot } = useRoleAuthorization()
  const formatDate = useFormatDate()

  return (
    <Paper className="overflow-hidden">
      <TableGrid
        loading={loading}
        collection={data}
        fields={[
          {
            title: formatMessage({
              id: 'models.shipment-partner-label.id',
            }),
            render: (label) => label.id && label.id,
          },
          {
            title: formatMessage({
              id: 'models.shipment-partner-label.company-name',
            }),
            render: (label) =>
              label.partnerAddress &&
              label.partnerAddress.contactDetail &&
              label.partnerAddress.contactDetail.company &&
              label.partnerAddress.contactDetail.company,
          },
          {
            title: formatMessage({
              id: 'models.shipment-partner-label.address',
            }),
            render: (label) =>
              label.partnerAddress && <GroupLabelAddressLink address={label.partnerAddress} />,
          },

          {
            title: formatMessage({
              id: 'models.shipment-partner-label.tracking-number',
            }),
            render: (label) =>
              label.packages.length > 0 &&
              label.packages.map(({ trackingNumber }) => trackingNumber),
          },
          {
            title: formatMessage({
              id: 'models.shipment-partner-label.status',
            }),
            render: (label) =>
              label.status && <LabelStatusChip status={label.status} mapActiveStatus />,
          },
          {
            title: formatMessage({
              id: 'models.shipment-partner-label.last-update',
            }),
            render: (label) =>
              label.updateStatusDate && formatDate('datetime', label.updateStatusDate.slice(0, -1)),
          },
        ]}
        emptyLabel={formatMessage({ id: 'common.no-data' })}
        rowActions={[
          {
            title: formatMessage({ id: 'common.actions.track-history' }),
            icon: 'history',
            visible: ({ hasTrackingInfo }) => hasTrackingInfo,
            onClick: ({ id, packages }) =>
              navigate(`/tracking/${id}/${packages[0].trackingNumber}`),
            disabled: cannot('tracking', 'list'),
            mainAction: true,
          },
        ]}
      />
    </Paper>
  )
}

export default PreviewTable
