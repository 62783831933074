/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react'
import greinerLogo from '@local/assets/images/greiner-logo.svg'
import greinerStepOne from '@local/assets/images/greiner-dashboard-step-one.svg'
import greinerStepTwo from '@local/assets/images/greiner-dashboard-step-two.svg'
import greinerStepThree from '@local/assets/images/greiner-dashboard-step-three.svg'
import { FaCheckCircle, FaExclamationTriangle } from 'react-icons/fa'
import { useBoxes, useGreinerItemsReturned, useUserRankingByCupCount } from '@web-panel/oc-api'
import { Box } from '@web-panel/api'
import { useParams } from 'react-router-dom'
import { BoxStatus } from './Components/BoxStatus'
import { PageStyles } from './PageStyles'

const GreinerDashboard: React.FC = () => {
  const { outletId } = useParams()
  const { data: greinerItemsReturned, refetch: refetchGreinerItemsReturned } =
    useGreinerItemsReturned({
      outletId,
    })

  const { data: itemsCountByConsumers = [], refetch: refetchUserRankingByCupCount } =
    useUserRankingByCupCount({
      outletId,
    })

  const { data: boxes = [], refetch: refetchBoxes } = useBoxes(
    {
      outletId,
    },
    {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'cache-first',
    }
  )

  const fetchData = async () => {
    await refetchGreinerItemsReturned()
    await refetchUserRankingByCupCount()
    await refetchBoxes()
  }

  useEffect(() => {
    // Fetch data initially on mount
    fetchData()
    // Set interval to call fetchData every minute (60000 ms)
    const intervalId = setInterval(fetchData, 60000)
    // Clear interval on component unmount
    return () => clearInterval(intervalId)
  }, [])

  return (
    <div style={PageStyles.containerStyle}>
      {/* Sidebar */}
      <aside style={PageStyles.sidebarStyle}>
        <img src={greinerLogo} alt="Greiner Logo" style={PageStyles.logoStyle} />
        <div style={PageStyles.stepStyle}>
          <img src={greinerStepOne} alt="Scan QR" style={PageStyles.stepImgStyle} />
        </div>
        <div style={PageStyles.stepStyle}>
          <img src={greinerStepTwo} alt="Tap on Scanner" style={PageStyles.stepImgStyle} />
        </div>
        <div style={PageStyles.stepStyle}>
          <img src={greinerStepThree} alt="Drop in box" style={PageStyles.stepImgStyle} />
        </div>
      </aside>

      {/* Main Content */}
      <main style={PageStyles.mainContentStyle}>
        <h1 style={PageStyles.dashboardTitleStyle}>Dashboard</h1>

        {/* KPI Section */}
        <section style={PageStyles.kpiCardsStyle}>
          <div style={PageStyles.kpiCardStyle}>
            <p style={PageStyles.kpiCardTitleStyle}>Cups Returned</p>
            <h2 style={PageStyles.kpiCardValueStyle}>
              {greinerItemsReturned?.count.toFixed(0) ?? 0}
              <span style={{ fontSize: '15px' }}>cups</span>
            </h2>
          </div>
          <div style={PageStyles.kpiCardStyle}>
            <p style={PageStyles.kpiCardTitleStyle}>Waste Saved</p>
            <h2 style={PageStyles.kpiCardValueStyle}>
              {greinerItemsReturned?.wasteSaved.toFixed(2) ?? 0}
              <span style={{ fontSize: '15px' }}>g</span>
            </h2>
          </div>
          <div style={PageStyles.kpiCardStyle}>
            <p style={PageStyles.kpiCardTitleStyle}>Co2 Saved</p>
            <h2 style={PageStyles.kpiCardValueStyle}>
              {greinerItemsReturned?.co2Saved.toFixed(2) ?? 0}
              <span style={{ fontSize: '15px' }}>Co2 g</span>
            </h2>
          </div>
        </section>

        {/* Box Fill Level and Leaderboard Section */}
        <div style={PageStyles.boxFillLeaderboardSectionStyle}>
          <div style={PageStyles.boxStyle}>
            <h3 style={{ fontSize: 24, fontWeight: 'bold', color: 'black' }}>Box fill level</h3>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
              }}
            >
              {boxes.map((item) => (
                <BoxStatus
                  key={item.coreId}
                  id={item.coreId}
                  value={Number(item.fillLevel!.toFixed(0)) ?? 0}
                  label={`${item.fillLevel!.toFixed(0)}%`}
                  color={getBoxesFillColor(item)}
                  icon={getBoxesFillStatusIcon(item)}
                />
              ))}
            </div>
          </div>

          {/* Leaderboard */}
          <div style={PageStyles.leaderboardStyle}>
            <h3
              style={{
                fontSize: 24,
                fontWeight: 'bold',
                color: 'black',
                marginBottom: 20,
              }}
            >
              Sustainability Leaderboard
            </h3>
            <table style={PageStyles.tableStyle}>
              <thead>
                <tr>
                  <th style={PageStyles.thStyle}>Position</th>
                  <th style={PageStyles.thStyle}>Name</th>
                  <th style={PageStyles.thStyle}>Cup Count</th>
                </tr>
              </thead>
              <tbody>
                {itemsCountByConsumers?.slice(0, 10)?.map((consumer, index) => (
                  <tr>
                    <td style={PageStyles.thTdStyle}>No. {index + 1}</td>
                    {(consumer?.userFirstName === null || consumer?.userFirstName === undefined) &&
                    (consumer?.userLastName === null || consumer?.userLastName === undefined) ? (
                      <td style={PageStyles.thTdStyle}>Unknown User</td>
                    ) : (
                      <td style={PageStyles.thTdStyle}>
                        {`${consumer?.userFirstName ?? ''} ${consumer?.userLastName?.[0] ?? ''}${
                          consumer?.userLastName?.slice(1).replace(/./g, '*') ?? ''
                        }`}
                      </td>
                    )}
                    <td style={PageStyles.thTdStyle}>{consumer.count}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </main>
    </div>
  )

  function getBoxesFillStatusIcon(item: Box): React.ReactNode {
    return item.fillLevel! <= 25 ? (
      <FaCheckCircle />
    ) : item.fillLevel! <= 50 ? (
      <FaCheckCircle />
    ) : item.fillLevel! <= 75 ? (
      <FaExclamationTriangle />
    ) : (
      <FaExclamationTriangle />
    )
  }

  function getBoxesFillColor(item: Box): string {
    return item.fillLevel! <= 25
      ? '#5EC282'
      : item.fillLevel! <= 50
      ? '#F7C844'
      : item.fillLevel! <= 75
      ? '#F08C37'
      : '#E05A33'
  }
}

export default GreinerDashboard
