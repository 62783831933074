import { FC } from 'react'
import { Paper, TableGrid, useFormatDate } from '@web-panel/shared'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useRoleAuthorization } from '@local/components/RoleAuthorized'
import { GroupLabelStatus, GroupLabel, MapGroupType } from '@web-panel/api'
import LabelStatusChip from '@local/components/LabelStatusChip'
import GroupLabelNameLink from '@local/components/GroupLabelNameLink'
import { useSorting } from '../sorting'

type IndexTableProps = {
  data: GroupLabel[]
  loading: boolean
  showGroupNameAsLink?: boolean
  onActivate?: (groupLabel: GroupLabel) => void
}

const IndexTable: FC<IndexTableProps> = ({ data, loading, showGroupNameAsLink, onActivate }) => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const { can, cannot } = useRoleAuthorization()
  const formateDate = useFormatDate()
  const { field, direction, handleToggleSort } = useSorting()

  return (
    <Paper className="overflow-hidden">
      <TableGrid
        loading={loading}
        collection={data}
        fields={[
          {
            title: formatMessage({ id: 'models.shipment-group-label.id' }),
            render: (groupLabel) => groupLabel.externalId,
          },
          {
            title: formatMessage({ id: 'models.shipment-group-label.name' }),
            render: (groupLabel) =>
              groupLabel &&
              (showGroupNameAsLink ? (
                <GroupLabelNameLink groupLabel={groupLabel} />
              ) : (
                groupLabel.sourceShipmentSequence?.name
              )),
          },
          {
            title: formatMessage({
              id: 'models.shipment-group-label.type',
            }),
            render: (groupLabel) =>
              groupLabel.sourceShipmentSequence &&
              groupLabel.sourceShipmentSequence.type &&
              MapGroupType(groupLabel.sourceShipmentSequence.type),
          },
          {
            title: formatMessage({ id: 'models.shipment-group-label.status' }),
            render: (groupLabel) =>
              groupLabel?.status && <LabelStatusChip status={groupLabel?.status} />,
          },
          {
            title: formatMessage({
              id: 'models.shipment-group-label.label-pdf',
            }),
            render: ({ unifiedLabelUrl }) =>
              unifiedLabelUrl && (
                <a
                  target="_blank"
                  href={unifiedLabelUrl}
                  rel="noreferrer"
                  onClick={(e) => e.stopPropagation()}
                >
                  <FormattedMessage id="common.actions.download" />
                </a>
              ),
          },
          {
            title: formatMessage({
              id: 'models.shipment-group-label.date-updated',
            }),
            render: (groupLabel) =>
              groupLabel.updateStatusDate &&
              formateDate('human-datetime', groupLabel.updateStatusDate.slice(0, -1)),
            sorting: {
              active: field === 'UPDATE_STATUS_DATE',
              direction,
              onToggle: handleToggleSort('UPDATE_STATUS_DATE'),
            },
          },
        ]}
        onClickRow={({ id }) => navigate(`/group-labels/${id}`)}
        rowClickable={can('groupLabels', 'labelsList')}
        emptyLabel={formatMessage({ id: 'common.no-data' })}
        rowActions={[
          {
            title: formatMessage({ id: 'common.actions.activate' }),
            onClick: (label) => onActivate!(label),
            disabled: cannot('groupLabels', 'activate'),
            visible: ({ status }) => status === GroupLabelStatus.Idle,
          },
        ]}
      />
    </Paper>
  )
}

export default IndexTable
