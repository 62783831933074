import { FC } from 'react'
import { Paper, TableGrid, useFormatDate } from '@web-panel/shared'
import { useIntl } from 'react-intl'
import { Tracking } from '@web-panel/api'

type IndexTableProps = {
  data: Tracking[]
  loading: boolean
}

const IndexTable: FC<IndexTableProps> = ({ data, loading }) => {
  const { formatMessage } = useIntl()
  const formatDate = useFormatDate()

  return (
    <Paper className="overflow-hidden">
      <TableGrid
        loading={loading}
        collection={data}
        fields={[
          {
            title: formatMessage({
              id: 'models.shipment-label-tracking.description',
            }),
            render: (tracking) => tracking.originDescription && tracking.originDescription,
          },
          {
            title: formatMessage({
              id: 'models.shipment-partner-label.tracking-status',
            }),
            render: (tracking) => tracking.originStatus && tracking.originStatus,
          },
          {
            title: formatMessage({
              id: 'models.shipment-partner-label.update-date',
            }),
            render: (tracking) =>
              tracking.originUpdateStatusDate &&
              formatDate('datetime', tracking.originUpdateStatusDate.slice(0, -1)),
          },
        ]}
        rowClickable={false}
        emptyLabel={formatMessage({ id: 'common.no-data' })}
      />
    </Paper>
  )
}

export default IndexTable
