import { gql } from '@apollo/client'
import {
  Box,
  BoxStatus,
  SortDirection,
  boxFields,
  BoxActivityHistoryElement,
  boxActivityHistoryElementFields,
  BoxTask,
  boxTaskFields,
  publicBoxDataFields,
  PublicBoxData,
} from '@web-panel/api'

export type BoxesDocumentInput = {
  limit: number
  offset?: number
  search?: string
  outletId?: string
  status?: BoxStatus
  minBattery?: number
  maxBattery?: number
  minItems?: number
  maxItems?: number
  startHeartbeatDateTime?: Date
  endHeartbeatDateTime?: Date
  startLastSignalDateTime?: Date
  endLastSignalDateTime?: Date
  fwVersion?: string
  cityCode?: string
  sortOrder?: SortDirection
  operationsInterval?: number
  sortBy?:
    | 'CREATE_DATE'
    | 'FILL_LEVEL'
    | 'BATTERY'
    | 'HEART_BEAT_DATE'
    | 'ITEMS'
    | 'LAST_SIGNAL_DATE'
    | 'CORE_ID'
    | 'MODEL'
    | 'STATUS'
    | 'OPERATIONS_LAST_VISIT_DATE'
}

export type BoxesDocumentResponse = {
  boxesCollection: {
    boxes: Box[]
    hasNext: boolean
  }
}

export const BoxesDocument = gql`
  query Boxes(
          $limit: Int!,
          $offset: Int,
          $search: String,
          $outletId: String,
          $status: String,
          $minBattery: Int,
          $maxBattery: Int,
          $minItems: Int,
          $maxItems: Int,
          $startHeartbeatDateTime: String,
          $endHeartbeatDateTime: String,
          $startLastSignalDateTime: String,
          $endLastSignalDateTime: String,
          $operationsInterval: Int,
          $fwVersion: String,
          $cityCode: String,
          $sortOrder: String,
          $sortBy: String
    ) {
    boxesCollection(
      limit: $limit,
      offset: $offset,
      search: $search,
      outletId: $outletId,
      status: $status,
      minBattery: $minBattery,
      maxBattery: $maxBattery,
      minItems: $minItems,
      maxItems: $maxItems,
      startHeartbeatDateTime: $startHeartbeatDateTime,
      endHeartbeatDateTime: $endHeartbeatDateTime,
      startLastSignalDateTime: $startLastSignalDateTime,
      endLastSignalDateTime: $endLastSignalDateTime,
      operationsInterval: $operationsInterval,
      fwVersion: $fwVersion,
      cityCode: $cityCode,
      sortOrder: $sortOrder,
      sortBy: $sortBy
    ) @rest(type: "BoxesCollection", path: "/partners-service/boxes?{args}") {
      boxes @type(name: "Box") {
        ${boxFields}
      }
      hasNext
    }
  }
`

export type BoxStatusAggregationDocumentInput = {
  search?: string
  status?: BoxStatus
  minBattery?: number
  maxBattery?: number
  minItems?: number
  maxItems?: number
  startHeartbeatDateTime?: Date
  endHeartbeatDateTime?: Date
  startLastSignalDateTime?: Date
  endLastSignalDateTime?: Date
  operationsInterval?: number
  fwVersion?: string
  cityCode?: string
}

export type BoxStatusAggregationDocumentResponse = {
  boxStatusAggregation: {
    boxCountByStatuses: {
      status: BoxStatus
      count: number
    }[]
  }
}

export const BoxStatusAggregationDocument = gql`
  query BoxStatusAggregation(
    $search: String
    $status: String
    $minBattery: Int
    $maxBattery: Int
    $minItems: Int
    $maxItems: Int
    $startHeartbeatDateTime: String
    $endHeartbeatDateTime: String
    $startLastSignalDateTime: String
    $endLastSignalDateTime: String
    $fwVersion: String
    $cityCode: String
    $operationsInterval: Int
  ) {
    boxStatusAggregation(
      search: $search
      status: $status
      minBattery: $minBattery
      maxBattery: $maxBattery
      minItems: $minItems
      maxItems: $maxItems
      startHeartbeatDateTime: $startHeartbeatDateTime
      endHeartbeatDateTime: $endHeartbeatDateTime
      startLastSignalDateTime: $startLastSignalDateTime
      endLastSignalDateTime: $endLastSignalDateTime
      fwVersion: $fwVersion
      cityCode: $cityCode
      operationsInterval: $operationsInterval
    )
      @rest(
        type: "BoxesStatusAggregation"
        path: "/partners-service/boxes/filter/aggregation?{args}"
      ) {
      boxCountByStatuses {
        status
        count
      }
    }
  }
`

export type BoxFWVersionAggregationDocumentInput = {
  search?: string
  status?: BoxStatus
  minBattery?: number
  maxBattery?: number
  minItems?: number
  maxItems?: number
  startHeartbeatDateTime?: Date
  endHeartbeatDateTime?: Date
  startLastSignalDateTime?: Date
  endLastSignalDateTime?: Date
  cityCode?: string
  operationsInterval?: number
}

export type BoxFWVersionAggregationDocumentResponse = {
  boxFwVersionAggregation: {
    boxCountByFwVersions: {
      fwVersion: string
      count: number
    }[]
  }
}

export const BoxFWVersionAggregationDocument = gql`
  query BoxFwVersionAggregation(
    $search: String
    $status: String
    $minBattery: Int
    $maxBattery: Int
    $minItems: Int
    $maxItems: Int
    $startHeartbeatDateTime: String
    $endHeartbeatDateTime: String
    $startLastSignalDateTime: String
    $endLastSignalDateTime: String
    $cityCode: string
    $operationsInterval: Int
  ) {
    boxFwVersionAggregation(
      search: $search
      status: $status
      minBattery: $minBattery
      maxBattery: $maxBattery
      minItems: $minItems
      maxItems: $maxItems
      startHeartbeatDateTime: $startHeartbeatDateTime
      endHeartbeatDateTime: $endHeartbeatDateTime
      startLastSignalDateTime: $startLastSignalDateTime
      endLastSignalDateTime: $endLastSignalDateTime
      cityCode: $cityCode
      operationsInterval: $operationsInterval
    )
      @rest(
        type: "FWVersionAggregation"
        path: "/partners-service/boxes/filter/aggregation/fw?{args}"
      ) {
      boxCountByFwVersions {
        fwVersion
        count
      }
    }
  }
`

export type GetBoxDocumentInput = {
  id: string
}
export type GetBoxDocumentResponse = {
  box: Box
}
export const GetBoxDocument = gql`
  query Box($id: String!) {
    box(id: $id) @rest(type: "Box", path: "/partners-service/boxes/{args.id}") {
      ${boxFields}
    }
  }
`

export type CreateBoxBody = {
  file: File
}
export type CreateBoxesDocumentInput = CreateBoxBody & {
  bodySerializer?: (data: CreateBoxBody, headers: Headers) => { body: FormData; headers: Headers }
}
export type CreateBoxesDocumentResponse = void
export const CreateBoxesDocument = gql`
  mutation CreateBoxes($input: any, $bodySerializer: any) {
    createBoxes: publish(input: $input)
      @rest(
        type: "CreateBoxesResponse"
        path: "/partners-service/boxes"
        method: "POST"
        bodySerializer: $bodySerializer
      ) {
      none
    }
  }
`

export type AssignBoxToOutletDocumentInput = {
  boxId: string
  outletId: string
  input: {
    keepLocation: boolean
    searchableInOutlet: boolean
  }
}
export type AssignBoxToOutletDocumentResponse = void
export const AssignBoxToOutletDocument = gql`
  mutation AssignBoxToOutletDocument($boxId: String!, $outletId: String!, $input: any) {
    assignBoxToOutletDocument: publish(boxId: $boxId, outletId: $outletId, input: $input)
      @rest(
        type: "AssignBoxOutletResponse"
        path: "/partners-service/boxes/{args.boxId}/assign/{args.outletId}"
        method: "PUT"
      ) {
      none
    }
  }
`

export type UpdateBoxDocumentInput = {
  id: string
  input: Partial<{
    macAddress: Box['macAddress']
    coreId: Box['coreId']
    imei: Box['imei']
    outledId: Box['outletId']
    status: Box['status']
    type: Box['type']
    reported: Box['reported']
    headId: Box['headId']
    bodyId: Box['bodyId']
    locationId: string | null
    model: Box['model']
    producerId: Box['producerId']
    powerSupplyType: Box['powerSupplyType']
    operationsInterval: Box['operationsInterval']
    searchableInOutlet: Box['searchableInOutlet']
    priority: Box['priority']
    acceptanceToleranceTof: Box['acceptanceToleranceTof']
    lightControlEnabled: Box['lightControlEnabled']
    cupsCountThreshold: Box['cupsCountThreshold']
    cupsCountFull: Box['cupsCountFull']
    fillLevelThreshold: Box['fillLevelThreshold']
    fillLevelFull: Box['fillLevelFull']
  }>
}
export type UpdateBoxDocumentResponse = {
  updateBox: Box
}

export const UpdateBoxDocument = gql`
  mutation UpdateBox($id: String!, $input: any) {
    updateBox: publish(id: $id, input: $input) @rest(type: "Box", path: "/partners-service/boxes/{args.id}", method: "PUT") {
      ${boxFields}
    }
  }
`

export type BoxActivityDocumentInput = {
  boxId: string
  limit?: number
  offset?: number
}

export type BoxActivityDocumentResponse = {
  boxActivity: {
    activityHistory: BoxActivityHistoryElement[]
    hasNext: boolean
  }
}

export const BoxActivityDocument = gql`
  query BoxActivity($boxId: String!, $limit: Number, $offset: Number) {
    boxActivity(boxId: $boxId, limit: $limit, offset: $offset) @rest(type: "BoxActivityCollection", path: "/partners-service/boxes/{args.boxId}/history?limit={args.limit}&offset={args.offset}") {
      activityHistory @type(name: "BoxActivity") {
        ${boxActivityHistoryElementFields}
      }
      hasNext
    }
  }
`

export type BoxTasksDocumentInput = {
  boxId: string
  offset?: string
  limit?: string
}

export type BoxTasksDocumentResponse = {
  boxTasks: {
    tasks: BoxTask[]
    hasNext: boolean
  }
}

export const BoxTasksDocument = gql`
  query BoxTasks($boxId: String!, $offset: Int, $limit: Int) {
    boxTasks(boxId: $boxId, offset: $offset, limit: $limit)
      @rest(type: "BoxTaskCollection", path: "/partners-service/boxes/{args.boxId}/tasks?limit={args.limit}&offset={args.offset}") {
        tasks @type(name: "BoxTask") {
          ${boxTaskFields}
        }
        hasNext
      }
  }
`

export type ResetBoxItemsDocumentInput = {
  boxId: string
}
export type ResetBoxItemsDocumentResponse = {
  resetBoxItems: {
    id: string
    vaultPublicUrl: string
  }
}
export const ResetBoxItemsDocument = gql`
  mutation ResetBoxItemsDocument($boxId: String!) {
    resetBoxItems(boxId: $boxId)
      @rest(
        type: "Box"
        path: "/partners-service/boxes/{args.boxId}/reset/items"
        method: "DELETE"
      ) {
      ${boxFields}
    }
  }
`

export type PublicBoxDocumentInput = {
  id: string
}
export type PublicBoxDocumentResponse = {
  box: PublicBoxData
}
export const PublicBoxDocument = gql`
  query PublicBox($id: String!) {
    box(id: $id) @rest(type: "PublicBox", path: "/partners-service/public/boxes/{args.id}") {
      ${publicBoxDataFields}
    }
  }
`
