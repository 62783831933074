import { Address, addressFields } from './address'

export type Packages = {
  trackingNumber: string
  trackingUrl: string
}

export type GroupLabelId = {
  id: string
}

export const packagesFields = `
  trackingNumber
  trackingUrl
`
export const groupIdFields = `
  id
`

export type Label = {
  id: string
  trackingNumber: string
  status: LabelStatus
  partnerAddress: Address
  hasTrackingInfo: boolean
  packages: Packages[]
  groupLabelId: GroupLabelId
  groupLabelExternalId: number
  updateStatusDate: string
  lastTrackingUpdateDate: string
}

export enum LabelStatus {
  Idle = 'IDLE',
  Active = 'ACTIVE',
  Delivered = 'DELIVERED',
  Interrupted = 'INTERRUPTED',
  Deactivated = 'DEACTIVATED',
}

export const labelFields = `
  id
  trackingNumber
  status
  partnerAddress {
    ${addressFields}
  }
  hasTrackingInfo: true
  packages{
    ${packagesFields}
  }
  groupLabelId{
    ${groupIdFields}
  }
  groupLabelExternalId
  updateStatusDate
  lastTrackingUpdateDate
`
