import { useQuery } from '@apollo/client'
import { QueryHook } from '@web-panel/api'
import {
  GetItemsReturnedDocument,
  GetItemsReturnedDocumentInput,
  GetItemsReturnedDocumentResponse,
  GetUserRankingByCupCountDocument,
  GetUserRankingByCupCountDocumentResponse,
  GetUserRankingByCupCountInput,
} from '../docs'

export const useGreinerItemsReturned: QueryHook<
  GetItemsReturnedDocumentInput,
  GetItemsReturnedDocumentResponse['greinerItemsReturned']
> = ({ outletId }, options) => {
  const { data, loading, refetch } = useQuery<GetItemsReturnedDocumentResponse>(
    GetItemsReturnedDocument,
    {
      variables: { outletId },
      ...options,
      context: {
        apiVersion: 'greiner',
      },
    }
  )

  return {
    loading,
    data: data?.greinerItemsReturned,
    refetch: async () => {
      await refetch()
    },
  }
}

export const useUserRankingByCupCount: QueryHook<
  GetUserRankingByCupCountInput,
  GetUserRankingByCupCountDocumentResponse['itemsCountCollection']['itemsCountByConsumers']
> = ({ outletId }, options) => {
  const { data, loading, refetch } = useQuery<GetUserRankingByCupCountDocumentResponse>(
    GetUserRankingByCupCountDocument,
    {
      variables: { outletId },
      ...options,
    }
  )

  return {
    loading,
    data: data?.itemsCountCollection.itemsCountByConsumers,
    refetch: async () => {
      await refetch()
    },
  }
}
