import { FC } from 'react'
import {
  TextField,
  FormControlLabel,
  Switch,
  LabeledSelect,
  FormSection,
  LabeledSwitch,
} from '@web-panel/shared'
import { useIntl } from 'react-intl'
import { BoxStatus, BoxType, BoxPowerSupplyType } from '@web-panel/api'
import { FormikProps, FormikErrors } from 'formik'
import * as Yup from 'yup'
import { operationIntervals } from '@local/constants'
import { OutletAutocomplete } from '@local/components/Autocomplete/OutletAutocomplete'
import LocationInput, {
  LocationData,
  validationSchema as locationValidationSchema,
} from './LocationInput'

export type FormFields = {
  imei: string | null
  macAddress: string
  coreId: string
  headId: string | null
  bodyId: string | null
  model: string | null
  producerId: string | null
  status: BoxStatus | null
  type: BoxType
  outletId: string | null
  searchableInOutlet: boolean
  reported: boolean
  location: LocationData | null
  powerSupplyType: BoxPowerSupplyType | null
  operationsInterval: number
  heartbeatEnabled: boolean
  priority: number
  acceptanceToleranceTof: number
  lightControlEnabled: boolean
  fillLevelThreshold: number
  fillLevelFull: number
  cupsCountThreshold: number
  cupsCountFull: number
}

export const validationSchema = Yup.object().shape({
  imei: Yup.string().nullable(),
  macAddress: Yup.string().required(),
  coreId: Yup.string().required(),
  location: locationValidationSchema.nullable(),
  powerSupplyType: Yup.string().nullable(),
  heartbeatEnabled: Yup.boolean().required(),
  priority: Yup.number().min(0).max(5).required(),
  acceptanceToleranceTof: Yup.number().min(-20).max(50).required(),
  fillLevelThreshold: Yup.number().min(0).max(200).required(),
  fillLevelFull: Yup.number().min(0).max(200).required(),
  cupsCountThreshold: Yup.number().min(0).max(200).required(),
  cupsCountFull: Yup.number().min(0).max(200).required(),
  lightControlEnabled: Yup.boolean().required(),
})

type UpdateBoxFormProps = FormikProps<FormFields>
export const UpdateBoxForm: FC<UpdateBoxFormProps> = ({
  values,
  errors,
  setFieldValue,
  handleChange,
  handleSubmit,
}) => {
  const { formatMessage } = useIntl()

  return (
    <form className="flex flex-col justify-center w-full" onSubmit={handleSubmit}>
      <div className="flex flex-col p-16 sm:p-24 max-w-2xl">
        <FormSection firstSection>
          <div>
            <FormControlLabel
              control={<Switch checked={values.reported} onChange={handleChange('reported')} />}
              label={formatMessage({ id: 'models.box.reported' })}
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={values.heartbeatEnabled}
                  onChange={handleChange('heartbeatEnabled')}
                />
              }
              label={formatMessage({ id: 'models.box.heartbeat-enabled' })}
            />
          </div>
          <TextField
            fullWidth
            className="mt-16"
            value={values.imei ?? ''}
            onChange={handleChange('imei')}
            label={formatMessage({ id: 'models.box.imei' })}
            error={!!errors.imei}
          />
          <TextField
            fullWidth
            className="mt-16"
            value={values.macAddress}
            onChange={handleChange('macAddress')}
            label={formatMessage({ id: 'models.box.mac-address' })}
            error={!!errors.macAddress}
          />
          <TextField
            fullWidth
            className="mt-16"
            value={values.coreId}
            onChange={handleChange('coreId')}
            label={formatMessage({ id: 'models.box.core-id' })}
            error={!!errors.coreId}
          />
          <TextField
            fullWidth
            className="mt-16"
            value={values.headId ?? ''}
            onChange={handleChange('headId')}
            label={formatMessage({ id: 'models.box.head-id' })}
            error={!!errors.headId}
          />
          <TextField
            fullWidth
            className="mt-16"
            value={values.bodyId ?? ''}
            onChange={handleChange('bodyId')}
            label={formatMessage({ id: 'models.box.body-id' })}
            error={!!errors.bodyId}
          />
          <TextField
            fullWidth
            className="mt-16"
            value={values.model ?? ''}
            onChange={handleChange('model')}
            label={formatMessage({ id: 'models.box.model' })}
            error={!!errors.model}
          />
          <TextField
            fullWidth
            className="mt-16"
            value={values.producerId ?? ''}
            onChange={handleChange('producerId')}
            label={formatMessage({ id: 'models.box.producer-id' })}
            error={!!errors.producerId}
          />
          <LabeledSelect
            fullWidth
            className="mt-16"
            label={formatMessage({ id: 'models.box.status' })}
            value={values.status ?? ''}
            options={Object.values(BoxStatus)}
            optionTitle={(option) => formatMessage({ id: `box-statuses.${option}` })}
            onChange={(e) => handleChange('status')(e.target.value)}
            error={!!errors.status}
          />
          <LabeledSelect
            fullWidth
            className="mt-16"
            label={formatMessage({ id: 'models.box.type' })}
            value={values.type ?? ''}
            onChange={(e) => handleChange('type')(e.target.value)}
            options={Object.values(BoxType)}
            optionTitle={(option) => formatMessage({ id: `box-types.${option}` })}
            error={!!errors.type}
          />
          <LabeledSelect
            fullWidth
            className="mt-16"
            label={formatMessage({ id: 'models.box.power-supply-type' })}
            value={values.powerSupplyType ?? ''}
            onChange={(e) => handleChange('powerSupplyType')(e.target.value)}
            options={Object.values(BoxPowerSupplyType)}
            optionTitle={(option) => formatMessage({ id: `box-power-supply-types.${option}` })}
            error={!!errors.powerSupplyType}
          />
          <LabeledSelect<number>
            fullWidth
            className="mt-16"
            label={formatMessage({ id: 'models.box.operations-interval' })}
            value={values.operationsInterval}
            onChange={(e) => setFieldValue('operationsInterval', e.target.value)}
            options={operationIntervals}
            optionTitle={(option) =>
              formatMessage({ id: 'common.labels.hours' }, { value: option })
            }
            error={!!errors.operationsInterval}
          />
          <TextField
            fullWidth
            className="mt-16"
            value={values.priority}
            onChange={handleChange('priority')}
            label={formatMessage({ id: 'models.box.priority' })}
            error={!!errors.priority}
          />
          <TextField
            fullWidth
            className="mt-16"
            value={values.acceptanceToleranceTof}
            onChange={handleChange('acceptanceToleranceTof')}
            label={formatMessage({ id: 'models.box.acceptance-tolerance-tof' })}
            error={!!errors.acceptanceToleranceTof}
          />
          <TextField
            fullWidth
            className="mt-16"
            value={values.fillLevelThreshold}
            onChange={handleChange('fillLevelThreshold')}
            label={formatMessage({ id: 'models.box.fill-level-threshold' })}
            error={!!errors.fillLevelThreshold}
          />
          <TextField
            fullWidth
            className="mt-16"
            value={values.fillLevelFull}
            onChange={handleChange('fillLevelFull')}
            label={formatMessage({ id: 'models.box.fill-level-full' })}
            error={!!errors.fillLevelFull}
          />
          <TextField
            fullWidth
            className="mt-16"
            value={values.cupsCountThreshold}
            onChange={handleChange('cupsCountThreshold')}
            label={formatMessage({ id: 'models.box.cups-count-threshold' })}
            error={!!errors.cupsCountThreshold}
          />
          <TextField
            fullWidth
            className="mt-16"
            value={values.cupsCountFull}
            onChange={handleChange('cupsCountFull')}
            label={formatMessage({ id: 'models.box.cups-count-full' })}
            error={!!errors.cupsCountFull}
          />
          <OutletAutocomplete
            fullWidth
            className="mt-16"
            outletId={values.outletId}
            label={formatMessage({ id: 'models.box.outlet' })}
            onChange={(outlet) => setFieldValue('outletId', outlet?.id ?? null)}
          />
          <LabeledSwitch
            className="mt-16 block"
            label={formatMessage({ id: 'models.box.searchable-in-outlet' })}
            checked={values.searchableInOutlet}
            onChange={(e) => setFieldValue('searchableInOutlet', e.target.checked)}
          />
          <LabeledSwitch
            className="mt-16 block"
            label={formatMessage({ id: 'models.box.light-control-enabled' })}
            checked={values.lightControlEnabled}
            onChange={(e) => setFieldValue('lightControlEnabled', e.target.checked)}
          />
        </FormSection>
        <FormSection title={formatMessage({ id: 'models.box.location' })}>
          <LocationInput
            outletId={values.outletId}
            value={values.location?.editable ? values.location : null}
            onChange={(location) => setFieldValue('location', location)}
            errors={errors.location as FormikErrors<LocationData>}
          />
        </FormSection>
      </div>
    </form>
  )
}
