import { gql } from '@apollo/client'
import {
  GreinerItemsReturned,
  greinerItemsReturnedFields,
  ItemsCountByConsumers,
  itemsCountByConsumersFields,
} from '@web-panel/api'

export type GetItemsReturnedDocumentInput = {
  outletId?: string
}
export type GetItemsReturnedDocumentResponse = {
  greinerItemsReturned: GreinerItemsReturned
}
export const GetItemsReturnedDocument = gql`
  query GetGreinerItemsReturned($outletId: String!) {
    greinerItemsReturned(outletId: $outletId) @rest(type: "GreinerItemsReturned", path: "/operations-service/analytics/total/items/returned?outletId={args.outletId}") {
      ${greinerItemsReturnedFields}
    }
  }
`

export type GetUserRankingByCupCountInput = {
  outletId?: string
}
export type GetUserRankingByCupCountDocumentResponse = {
  itemsCountCollection: {
    itemsCountByConsumers: ItemsCountByConsumers[]
  }
}
export const GetUserRankingByCupCountDocument = gql`
  query ItemsCountByConsumers($outletId: String!) {
    itemsCountCollection(outletId: $outletId) @rest(type: "ItemsCountCollection", path: "/operations-service/items/consumer/aggregation?outletId={args.outletId}") {
    itemsCountByConsumers @type(name: "ItemsCountByConsumers") {
      ${itemsCountByConsumersFields}
      }
    }
  }
`
