import { useRef, VFC } from 'react'
import { Address, ScheduleStatus, useCurrentUser } from '@web-panel/api'
import {
  ConfirmationDialog,
  ConfirmationDialogRef,
  Paper,
  TableGrid,
  useFormatDate,
  useNotifications,
} from '@web-panel/shared'
import { useIntl } from 'react-intl'
import { useDeleteSchedule, useSchedules } from '@web-panel/oc-api'
import ScheduleStatusChip from '@local/components/ScheduleStatusChip'

type ScheduleTabProps = {
  data: Address
}

export const ScheduleTab: VFC<ScheduleTabProps> = ({ data }) => {
  const { formatMessage } = useIntl()
  const formatDate = useFormatDate()
  const {
    data: schedules = [],
    loading,
    refetch,
  } = useSchedules({ partnerShippingAddressId: data.id }, { fetchPolicy: 'no-cache' })
  const { request: cancelSchedule } = useDeleteSchedule()
  const { pushNotification } = useNotifications()
  const { data: currentUser } = useCurrentUser()
  const cancelConfirmationDialogRef = useRef<ConfirmationDialogRef>(null)
  let tempPickupRequestId = ''

  function isFutureDate(date: Date): boolean {
    const now = new Date() // Get the current date and time
    return date.getTime() > now.getTime() // Compare the time values (milliseconds since epoch)
  }

  const handleDelete = async () => {
    try {
      const userName = [currentUser?.firstName, currentUser?.lastName].join(' ')
      await cancelSchedule({
        pickupRequestId: tempPickupRequestId,
        reason: '',
        requestorName: userName,
      })
      refetch()
      pushNotification(
        'success',
        formatMessage({ id: 'common.notifications.successfully-deleted' })
      )
    } catch (e) {
      pushNotification('error', formatMessage({ id: 'common.notifications.unexpected-error' }))
    }
  }

  return (
    <Paper className="overflow-hidden">
      <TableGrid
        loading={loading}
        collection={schedules}
        fields={[
          {
            title: formatMessage({
              id: 'models.shipment-address-schedule.pickup-date',
            }),
            render: (schedule) =>
              schedule.planedPickupDate &&
              formatDate('date', schedule?.planedPickupDate?.slice(0, -1)),
          },
          {
            title: formatMessage({
              id: 'models.shipment-address-schedule.pickup-time',
            }),
            render: (schedule) =>
              `${
                schedule?.planedPickupDate &&
                formatDate('time', schedule?.planedPickupDate?.slice(0, -1))
              }${schedule.closeTime ? ` - ${schedule.closeTime}` : ''}`,
          },
          {
            title: formatMessage({
              id: 'models.shipment-address-schedule.dispatch-confirmation-numbers',
            }),
            render: (schedule) =>
              schedule.dispatchConfirmationNumbers && schedule.dispatchConfirmationNumbers,
          },
          {
            title: formatMessage({
              id: 'models.shipment-address-schedule.status',
            }),
            render: (schedule) =>
              schedule.canceledBy !== null && schedule.cancelDate !== null ? (
                <ScheduleStatusChip status={ScheduleStatus.Cancelled} />
              ) : undefined,
          },
        ]}
        emptyLabel={formatMessage({ id: 'common.no-data' })}
        rowActions={[
          {
            title: formatMessage({ id: 'common.actions.cancel' }),
            icon: 'cancel',
            visible: ({ planedPickupDate, canceledBy, cancelDate }) =>
              canceledBy === null &&
              cancelDate === null &&
              isFutureDate(new Date(planedPickupDate)),
            onClick: ({ id }) => {
              tempPickupRequestId = id
              cancelConfirmationDialogRef?.current?.open()
            },
          },
        ]}
      />
      <ConfirmationDialog
        ref={cancelConfirmationDialogRef}
        title={formatMessage({
          id: 'confirmation-dialogs.cancel-schedule.title',
        })}
        acceptTitle={formatMessage({ id: 'common.yes' })}
        cancelTitle={formatMessage({ id: 'common.no' })}
        onSubmit={() => handleDelete()}
      />
    </Paper>
  )
}
