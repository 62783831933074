export type GreinerItemsReturned = {
  count: number
  co2Saved: number
  wasteSaved: number
  holderId: string
}

export const greinerItemsReturnedFields = `
  count
  co2Saved
  wasteSaved
  holderId
`

export type ItemsCountByConsumers = {
  userId: string
  userExternalId: string
  userFirstName?: string
  userLastName?: string
  count: number
}

export const itemsCountByConsumersFields = `
  userId
  userExternalId
  userFirstName
  userLastName
  count
`
