import React from 'react'
import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

interface BoxStatusProps {
  value: number
  label: string
  id: string
  color: string
  icon: React.ReactNode
}

export const BoxStatus: React.FC<BoxStatusProps> = ({ value, label, id, color, icon }) => {
  return (
    <div style={{ width: 200, height: 200, margin: '25px' }}>
      <div style={{ position: 'relative' }}>
        <CircularProgressbar
          value={value}
          strokeWidth={12}
          styles={{
            // Customize the path, i.e. the "completed progress"
            path: {
              // Path color
              stroke: color,
            },
            // Customize the circle behind the path, i.e. the "total progress"
            trail: {
              // Trail color
              stroke: '#E9EDF0',
              strokeWidth: 6,
            },
            // Customize the text
            text: {
              // Text color
              fill: color,
              // Text size
              fontSize: '15px',
              fontWeight: 600,
            },
          }}
        />
        <div
          style={{
            position: 'absolute',
            top: '25%',
            left: '40%',
            right: '43%',
            fontSize: '40px',
            color,
          }}
        >
          {icon}
        </div>
        <p
          style={{
            position: 'absolute',
            top: '48%',
            left: '33%',
            right: '33%',
            textAlign: 'center',
            color: 'black',
            fontSize: '14px',
            fontWeight: 'bold',
          }}
        >
          {id}
        </p>
        <p
          style={{
            position: 'absolute',
            top: '60%',
            left: '38%',
            right: '33%',
            textAlign: 'center',
            color,
            fontSize: '20px',
            fontWeight: 'bold',
          }}
        >
          {label}
        </p>
      </div>
    </div>
  )
}
